<template>
  <q-form ref="editForm">
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-card class="cardClassDetailForm" title="LBLBASEINFO">
          <template slot="card-button">
            <q-btn-group outline >
              <!--저장-->
              <c-btn 
                v-show="editable&&!disabled" 
                :isSubmit="isSave"
                :url="saveUrl"
                :param="target"
                :mappingType="mappingType"
                label="LBLSAVE" 
                icon="save"
                @beforeAction="saveTarget"
                @btnCallback="saveTargetCallback" />
              <!--삭제-->  
            </q-btn-group>
          </template>
          <template slot="card-detail">
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <c-plant
                :required="true"
                :editable="editable"
                :disabled="disabled"
                name="plantCd"
                v-model="target.plantCd">
              </c-plant>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
              <!--제목-->
              <c-text
                :required="true"
                :editable="editable"
                :disabled="disabled"
                label="제목"
                name="targetTitle"
                v-model="target.targetTitle">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <c-datepicker
                :required="true"
                :editable="editable"
                :disabled="isOld"
                type="month"
                default="today"
                label="작성월"
                name="targetMonth"
                v-model="target.targetMonth"
                @datachange="datachange"
              />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <!--작성부서-->
              <c-dept 
                :required="true" 
                :editable="editable" 
                :disabled="disabled"
                type="edit" 
                label="LBLWRITEDEPT" 
                name="writeDeptCd" 
                v-model="target.writeDeptCd" />
            </div>
          </template>
        </c-card>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-tab
          type="tabcard"
          :tabItems.sync="tabItems"
          :inlineLabel="true"
          :height="tabHeight"
          v-model="tab"
          align="left"
        >
          <template v-slot:default="tab">
            <component
              :is="tab.component"
              :popupParam="popupParam"
              :target="target"
              :contentHeight="contentHeight"
              :disabled="disabled"
              :attachInfo="attachInfo"
              @getDetail="getDetail"
            />
          </template>
        </c-tab>
      </div>
      <c-dialog :param="popupOptions"></c-dialog>
    </div>
  </q-form>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'env-saf-kpi-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        sopEnvSafKpiId: '',
      }),
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      target: {
        sopEnvSafKpiId: '',
        plantCd: '',
        targetMonth: '',
        prevMonth: '',
        envSafStatusCd: '',
        targetTitle: '',
        writeDeptCd: '',
        regUserId: '',
        chgUserId: '',
        targetList: [],
      },
      planRow: null,
      editable: false,
      isSave: false,
      isApproval: false,
      detailUrl: '',
      saveUrl: '',
      mappingType: 'PUT',
      popupOptions: {
        isFull: false,
        width: '50%',
        target: null,
        title: '',
        visible: false,
        top: '50px',
        param: {},
        closeCallback: null,
      },
      tab: 'envSafKpiResult',
      tabItems: [
        // 업무일지 결과
        { name: 'envSafKpiResult', icon: 'list', label: '환경, 안전보건 KPI 실적', component: () => import(`${'@/pages/sop/esk/envSafKpiResult.vue'}`) },
        // 안전보건실적
        { name: 'envSafKpiPerformance', icon: 'construction', label: '환경, 안전보건 종합점검시트', component: () => import(`${'@/pages/sop/esk/envSafKpiPerformance.vue'}`) },
        { name: 'relatednear', icon: 'relatedNear', label: '관련 아차사고', component: () => import(`${'@/pages/sop/esk/envSafKpiNearAccident.vue'}`) },
      ],
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'ENV_SAF_KPI',
        taskKey: '',
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    tabHeight() {
      return String(this.contentHeight - 165);
    },
    isOld() {
      return Boolean(this.popupParam.sopEnvSafKpiId)
    },
    disabled() {
      return this.target.envSafStatusCd === 'ESK0000005'
        // 결재중인 경우 비활성화
        || this.target.approvalStatusCd === 'ASC0000001'
    },
    isPlanWriting() {
      return !this.isOld || this.target.envSafStatusCd === 'ESK0000001'
    },
    // [E] 결재관련 버튼 컨트롤
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.sop.esk.get.url;
      this.newTargetUrl = selectConfig.sop.esk.newtargets.url;
      this.saveUrl = transactionConfig.sop.esk.insert.url;
      // code setting
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.isOld) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.sopEnvSafKpiId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.target = _result.data
          this.$set(this.attachInfo, 'taskKey', this.popupParam.sopEnvSafKpiId)
          this.$emit('setRegInfo', _result.data)
        },);
      } else {
        // 신규등록 
        this.$set(this.target, 'prevMonth', this.$comm.getCalculatedDate(this.target.targetMonth + '-01', '-1m', 'YYYY-MM'))
        this.$set(this.target, 'writeDeptCd', this.$store.getters.user.deptCd)
        this.setResult();
      }
    },
    setResult() {
      this.$http.url = this.newTargetUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        prevMonth: this.target.prevMonth
      }
      this.$http.request((_result) => {
        this.target.targetList = _result.data
      },);
    },
    saveTarget() {
      if (this.isOld) {
        this.mappingType = 'PUT'
        this.saveUrl = transactionConfig.sop.esk.update.url;
      } else {
        this.mappingType = 'POST'
        this.saveUrl = transactionConfig.sop.esk.insert.url;
      }
      
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',  // 확인
            message: 'MSGSAVE', // 저장하시겠습니까?   // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.target.regUserId = this.$store.getters.user.userId
              this.target.chgUserId = this.$store.getters.user.userId
      
              this.isSave = !this.isSave
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveTargetCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.popupParam.sopEnvSafKpiId = result.data
      this.$set(this.attachInfo, 'taskKey', this.popupParam.sopEnvSafKpiId)
      this.$set(this.attachInfo, 'isSubmit', uid())
      this.getDetail();
    },
    datachange() {
      this.$set(this.target, 'prevMonth', this.$comm.getCalculatedDate(this.target.targetMonth + '-01', '-1m', 'YYYY-MM'))
      this.setResult();
    },
  }
};
</script>